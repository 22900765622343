const permission = JSON.parse(localStorage.getItem("userData"))
  ? JSON.parse(localStorage.getItem("userData")).authentication
  : {};

const MenuItems = [
  // {
  //   menu_title: "Dashboard",
  //   path: "/app/welcome",
  //   icon: "dashboard",
  //   category: "general",
  //   isMenuOpen: false,
  //   menu: "dashboard",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   child_routes: null,
  //   permission:
  //     permission && permission.Dashboard !== undefined
  //       ? permission && permission.Dashboard.includes("Dashboard")
  //       : false,
  // },
  {
    menu_title: "Master",
    path: "/app/master/",
    icon: <i className="fa fa-cube" aria-hidden="true"></i>,
    category: "modules",
    isMenuOpen: false,
    menu: "master",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission:
      permission?.Master !== undefined ? permission.Master.length > 0 : false,
    child_routes: [
      {
        path: "/app/master/user",
        menu_title: "User",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission?.Master && permission?.Master !== undefined
            ? permission &&
            permission?.Master &&
            permission?.Master?.includes("User_Master")
            : false,
      },

      {
        path: "/app/master/banner",
        menu_title: "Banner",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Banner_master")
            : false,
      },
      {
        path: "/app/master/announcement",
        menu_title: "Announcement",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Announcement_Master")
            : false,
      },

      {
        path: "/app/master/employee",
        menu_title: "Employee",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Employee_master")
            : false,
      },

      {
        path: "/app/master/download-category",
        menu_title: "Download Category",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission &&
            permission.Master.includes("Download_Category_Master")
            : false,
      },
      {
        path: "/app/master/download-section",
        menu_title: "Download Section",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission &&
            permission.Master.includes("Download_Section_Master")
            : false,
      },
      {
        path: "/app/master/department",
        menu_title: "Department",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Department_master")
            : false,
      },
      {
        path: "/app/master/vertical",
        menu_title: "Vertical",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Vertical_Master")
            : false,
      },

      {
        path: "/app/master/contact-card",
        menu_title: "Contact Card",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Contact_Card_Master")
            : false,
      },
      {
        path: "/app/master/ticket-category",
        menu_title: "Ticket Category",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Ticket_Category_Master")
            : false,
      },
      {
        path: "/app/master/topic-name",
        menu_title: "Topic Name",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Topic_Name_Master")
            : false,
      },
      {
        path: "/app/master/topic-media",
        menu_title: "Topic Media",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Topic_Media_Master")
            : false,
      },
      {
        path: "/app/master/faq",
        menu_title: "FAQ",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Faq_Master")
            : false,
      },
      {
        path: "/app/master/town-hall",
        menu_title: "TownHall",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: true,
        // permission && permission.Master !== undefined
        //   ? permission && permission.Master.includes("Town_Hall_Master")
        //   : false,
      },
      {
        path: "/app/master/quick-links",
        menu_title: "IMP Links",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Quick_Links")
            : false,
      },
      {
        path: "/app/master/induction-master",
        menu_title: "Induction Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Induction_Master")
            : false,
      },
    ],
  },

  {
    menu_title: "Reports",
    path: "/app/reports/",
    icon: "note_alt",
    category: "reports",
    isMenuOpen: false,
    menu: "reports",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission:
      permission?.ReportMaster !== undefined
        ? permission.ReportMaster.length > 0
        : false,
    child_routes: [

      {
        path: "/app/reports/Ticket",
        menu_title: "Ticket Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission && permission.ReportMaster.includes("Ticket_Report")
            : false,
      },
      {
        path: "/app/reports/login-report",
        menu_title: "Login Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission &&
            permission.ReportMaster.includes("Login_Report")
            : false,
      },

    ],
  },
  {
    menu_title: "Settings",
    path: "/app/master/setting",
    icon: <i className="fa fa-cog" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "order",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission: false,
    // permission && permission.SettingsMaster !== undefined
    //   ? permission && permission.SettingsMaster.includes("Setting_master")
    //   : false,
    child_routes: null,
  },
];

export default MenuItems;
