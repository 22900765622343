/**
 * Router service file
 */

// Async component
import {
  // AsyncDashboard1Component,
  AsyncUserMasterComponent,
  AsyncUserSettingsComponent,
  AsynSettingComponent,
  AsynProductComponent,
  AsynLoginReportComponent,
  AsynCustomerListComponent,
  AsynSellsReportComponent,
  AsyncBannerMasterComponent,
  AsynAbundantCartReportComponent,
  AsynTrendingProductReportComponent,
  AsynPurchaseComponent,
  AsynPurchaseHistoryComponent,
  AsynPurchaseReportComponent,
  AsyncEmployeeComponent,
  AsyncDepartmentComponent,
  AsyncVerticalComponent,
  AsyncContactCardComponent,
  AsyncAnnouncementMasterComponent,
  AsyncTicketCategoryComponent,
  AsynDownloadCategory,
  AsynDownloadSectionComponent,
  AsyncTopicNameComponent,
  AsyncTopicMediaComponent,
  AsyncFaqComponent,
  AsynTicketReportComponent,
  AsyncTownHallComponent,
  AsyncQuickLinksComponent,
  AsyncInductionComponent,
  AsyncWelcomeComponent,

} from "routes/ComponentLoader";
const permission = JSON.parse(localStorage.getItem("userData"))
  ? JSON.parse(localStorage.getItem("userData")).authentication
  : {};
const routes = [
  //Dashboard's routes & permissions.
  // {
  //   path: "dashboard",
  //   component: AsyncDashboard1Component,
  //   meta: { permission: true },
  // },

  // Default welcome page
  {
    path: 'welcome',
    component: AsyncWelcomeComponent,
    meta: {
      permission: true,
    },
  },

  //Master's routes & permissions.
  {
    path: "master/user",
    component: AsyncUserMasterComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("User_Master")
          : false,
    },
  },

  {
    path: "master/banner",
    component: AsyncBannerMasterComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Banner_master")
          : false,
    },
  },
  {
    path: "master/announcement",
    component: AsyncAnnouncementMasterComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Announcement_Master")
          : false,
    },
  },

  {
    path: "materialManagement/purchase",
    component: AsynPurchaseComponent,
    meta: {
      permission:
        permission && permission.MaterialManagement !== undefined
          ? permission &&
          permission.MaterialManagement.includes("Purchase_Inventory")
          : false,
    },
  },
  {
    path: "materialManagement/purchase_report",
    component: AsynPurchaseReportComponent,
    meta: {
      permission:
        permission && permission.MaterialManagement !== undefined
          ? permission &&
          permission.MaterialManagement.includes("Inventory_Report")
          : false,
    },
  },
  {
    path: "materialManagement/purchase_history",
    component: AsynPurchaseHistoryComponent,
    meta: {
      permission:
        permission && permission.MaterialManagement !== undefined
          ? permission &&
          permission.MaterialManagement.includes("Purchase_History")
          : false,
    },
  },

  {
    path: "master/setting",
    component: AsynSettingComponent,
    meta: {
      permission:
        permission && permission.SettingsMaster !== undefined
          ? permission && permission.SettingsMaster.includes("Setting_master")
          : false,
    },
  },
  {
    path: "master/product",
    component: AsynProductComponent,
    meta: {
      permission:
        permission && permission.ProductsMaster !== undefined
          ? permission && permission.ProductsMaster.includes("Products_master")
          : false,
    },
  },

  //list
  {
    path: "reports/login-report",
    component: AsynLoginReportComponent,
    meta: {
      permission: true,
      // permission && permission.OrderHistoryMaster !== undefined
      //   ? permission &&
      //     permission.OrderHistoryMaster.includes("Order_History_master")
      //   : false,
    },
  },
  {
    path: "views/customers",
    component: AsynCustomerListComponent,
    meta: {
      permission:
        permission && permission.SettingsMaster !== undefined
          ? permission && permission.SettingsMaster.includes("Setting_master")
          : false,
    },
  },

  {
    path: "reports/Sales",
    component: AsynSellsReportComponent,
    meta: {
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission && permission.ReportMaster.includes("Sales_master")
          : false,
    },
  },

  {
    path: "reports/Ticket",
    component: AsynTicketReportComponent,
    meta: {
      permission: true,
      // permission && permission.ReportMaster !== undefined
      //   ? permission && permission.ReportMaster.includes("Ticket_master")
      //   : false,
    },
  },
  {
    path: "reports/AbundantCart",
    component: AsynAbundantCartReportComponent,
    meta: {
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission &&
          permission.ReportMaster.includes("Abundant_Cart_Report")
          : false,
    },
  },
  {
    path: "reports/TrendingProduct",
    component: AsynTrendingProductReportComponent,
    meta: {
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission &&
          permission.ReportMaster.includes("Trending_Product_Report")
          : false,
    },
  },

  {
    path: "master/download-category",
    component: AsynDownloadCategory,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Download_Category_Master")
          : false,
    },
  },

  {
    path: "master/download-section",
    component: AsynDownloadSectionComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Download_Section_Master")
          : false,
    },
  },

  {
    path: "user-settings",
    component: AsyncUserSettingsComponent,
    meta: {
      permission:
        permission && permission.SettingsMaster !== undefined
          ? permission && permission.SettingsMaster.includes("Setting_master")
          : false,
    },
  },
  {
    path: "master/employee",
    component: AsyncEmployeeComponent,
    meta: {
      permission: true,
      // permission && permission.Master !== undefined
      //   ? permission && permission.Master.includes("Employee_master")
      //   : false,
    },
  },
  {
    path: "master/department",
    component: AsyncDepartmentComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Department_master")
          : false,
    },
  },
  {
    path: "master/vertical",
    component: AsyncVerticalComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Vertical_Master")
          : false,
    },
  },
  {
    path: "master/contact-card",
    component: AsyncContactCardComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Contact_Card_Master")
          : false,
    },
  },
  {
    path: "master/ticket-category",
    component: AsyncTicketCategoryComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Ticket_Category_Master")
          : false,
    },
  },

  {
    path: "master/topic-name",
    component: AsyncTopicNameComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Topic_Name_Master")
          : false,
    },
  },

  {
    path: "master/topic-media",
    component: AsyncTopicMediaComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Topic_Media_Master")
          : false,
    },
  },
  {
    path: "master/faq",
    component: AsyncFaqComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Faq_Master")
          : false,
    },
  },

  {
    path: "master/town-hall",
    component: AsyncTownHallComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("TownHall_Master")
          : false,
    },
  },
  {
    path: "master/quick-links",
    component: AsyncQuickLinksComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Quick_Links")
          : false,
    },
  },
  {
    path: "master/induction-master",
    component: AsyncInductionComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Induction_Master")
          : false,
    },
  },

];

export default routes;
